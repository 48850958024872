import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTimer } from '../hooks/useTimer';
import TimerDisplay from '../components/TimerDisplay';
import TimerControls from '../components/TimerControls';
import { Egg as EggIcon } from 'lucide-react';

const EGG_PRESETS = [
  { name: 'Soft Boiled', time: 360000 }, // 6 minutes
  { name: 'Medium Boiled', time: 480000 }, // 8 minutes
  { name: 'Hard Boiled', time: 600000 }, // 10 minutes
];

const Egg = () => {
  const [selectedTime, setSelectedTime] = useState(480000); // 8 minutes default

  const { time, isRunning, start, pause, reset } = useTimer({
    countDown: true,
    initialTime: selectedTime,
  });

  const formatTime = (ms: number) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return { hours: 0, minutes, seconds };
  };

  const formattedTime = formatTime(time);

  return (
    <>
      <Helmet>
        <title>Egg Timer | TimeMaster</title>
        <meta name="description" content="Perfect egg timer for soft, medium, or hard-boiled eggs. Get consistently perfect results every time." />
        <meta name="keywords" content="egg timer, cooking timer, boiled eggs, kitchen timer" />
        <link rel="canonical" href="/egg" />
        <meta property="og:title" content="Professional Egg Timer" />
        <meta property="og:description" content="Timer for perfectly cooked eggs - soft, medium, or hard-boiled." />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Egg Timer
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Perfect timing for your eggs
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8">
          <div className="flex justify-center items-center mb-8">
            <div className="bg-yellow-500 p-4 rounded-full">
              <EggIcon className="w-8 h-8 text-white" />
            </div>
          </div>

          <TimerDisplay
            hours={formattedTime.hours}
            minutes={formattedTime.minutes}
            seconds={formattedTime.seconds}
          />

          <TimerControls
            isRunning={isRunning}
            onStart={start}
            onPause={pause}
            onReset={reset}
          />
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
            Egg Presets
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {EGG_PRESETS.map((preset) => (
              <button
                key={preset.name}
                onClick={() => {
                  setSelectedTime(preset.time);
                  reset();
                }}
                className={`p-4 rounded-lg flex items-center justify-center gap-2 transition-colors
                  ${selectedTime === preset.time
                    ? 'bg-yellow-500 text-white'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white hover:bg-yellow-100 dark:hover:bg-gray-600'
                  }`}
              >
                <EggIcon className="w-4 h-4" />
                {preset.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Egg;