import React from 'react';
import { Link } from 'react-router-dom';
import { Timer, Watch, Hourglass, Brain, Egg, Users } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

const features = [
  {
    name: 'Stopwatch',
    description: 'Precise timing with lap functionality',
    icon: Watch,
    href: '/stopwatch',
    color: 'bg-blue-500',
  },
  {
    name: 'Countdown',
    description: 'Customizable countdown with presets',
    icon: Timer,
    href: '/countdown',
    color: 'bg-green-500',
  },
  {
    name: 'Interval Timer',
    description: 'HIIT and interval training timer',
    icon: Hourglass,
    href: '/interval',
    color: 'bg-purple-500',
  },
  {
    name: 'Pomodoro',
    description: 'Focus and productivity timer',
    icon: Brain,
    href: '/pomodoro',
    color: 'bg-red-500',
  },
  {
    name: 'Egg Timer',
    description: 'Perfect timing for cooking',
    icon: Egg,
    href: '/egg',
    color: 'bg-yellow-500',
  },
  {
    name: 'Meeting Timer',
    description: 'Keep your meetings on track',
    icon: Users,
    href: '/meeting',
    color: 'bg-indigo-500',
  },
];

const Home = () => {
  return (
    <>
      <Helmet>
        <title>TimeMaster - Professional Time Management Suite</title>
        <meta name="description" content="Comprehensive time management suite featuring stopwatch, countdown, interval, pomodoro, egg, and meeting timers." />
        <meta name="keywords" content="timer, stopwatch, pomodoro, interval timer, HIIT timer, meeting timer" />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl">
            Professional Time Management
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
            Choose from our suite of specialized timers designed for every timing need
          </p>
        </div>

        <div className="mt-16">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <Link
                key={feature.name}
                to={feature.href}
                className="relative group"
              >
                <div className="relative flex flex-col p-6 bg-white dark:bg-gray-800 rounded-2xl shadow-xl transition-all duration-300 hover:scale-105">
                  <div className={`p-3 ${feature.color} rounded-lg w-12 h-12 flex items-center justify-center ring-8 ring-white dark:ring-gray-800`}>
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <h3 className="mt-4 text-lg font-semibold leading-7 text-gray-900 dark:text-white">
                    {feature.name}
                  </h3>
                  <p className="mt-2 text-base leading-7 text-gray-600 dark:text-gray-300">
                    {feature.description}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;