import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTimer } from '../hooks/useTimer';
import TimerDisplay from './TimerDisplay';
import TimerControls from './TimerControls';
import { Timer, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { getTimerContent } from '../utils/timerContent';

interface PresetTimerProps {
  milliseconds: number;
  title: string;
  path: string;
  unit: 'second' | 'minute' | 'hour';
  value: number;
}

const PresetTimer: React.FC<PresetTimerProps> = ({
  milliseconds,
  title,
  path,
  unit,
  value,
}) => {
  const content = getTimerContent(value, unit);

  const { time, isRunning, start, pause, reset } = useTimer({
    countDown: true,
    initialTime: milliseconds,
  });

  const formatTime = (ms: number) => {
    const hours = Math.floor(ms / 3600000);
    const minutes = Math.floor((ms % 3600000) / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    const milliseconds = ms % 1000;
    return { hours, minutes, seconds, milliseconds };
  };

  const formattedTime = formatTime(time);
  const showMilliseconds = unit === 'second' && value <= 10;

  return (
    <>
      <Helmet>
        <title>{title} | TimeMaster</title>
        <meta name="description" content={`Professional ${value}-${unit} countdown timer. ${content.description}`} />
        <meta name="keywords" content={`${value} ${unit} timer, countdown timer, online timer, professional timer`} />
        <link rel="canonical" href={path} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={content.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={path} />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4">
        <Link 
          to="/countdown"
          className="inline-flex items-center text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 mb-6"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Timer Selection
        </Link>

        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            {title}
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 mb-4">
            {content.description}
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8">
          <div className="flex justify-center items-center mb-8">
            <div className="bg-blue-500 p-4 rounded-full">
              <Timer className="w-8 h-8 text-white" />
            </div>
          </div>

          <TimerDisplay
            hours={formattedTime.hours}
            minutes={formattedTime.minutes}
            seconds={formattedTime.seconds}
            milliseconds={formattedTime.milliseconds}
            showMilliseconds={showMilliseconds}
          />

          <TimerControls
            isRunning={isRunning}
            onStart={start}
            onPause={pause}
            onReset={reset}
          />
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
            About {title}
          </h2>
          <div className="prose dark:prose-invert max-w-none">
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              {content.about}
            </p>
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">Features:</h3>
            <ul className="list-disc pl-6 mb-4 text-gray-700 dark:text-gray-300">
              <li>Precise {value}-{unit} countdown</li>
              <li>Clear, easy-to-read display</li>
              <li>Start, pause, and reset functionality</li>
              <li>Audio notifications when timer completes</li>
              <li>Works offline</li>
            </ul>
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">Common Uses:</h3>
            <ul className="list-disc pl-6 text-gray-700 dark:text-gray-300">
              {content.uses.map((use, index) => (
                <li key={index}>{use}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PresetTimer;