import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import PresetTimer from '../components/PresetTimer';
import { parseTimerParams, formatTimerPath, formatTimerTitle } from '../utils/timerRoutes';

const DynamicTimer = () => {
  const { duration } = useParams<{ duration: string }>();
  const timerParams = duration ? parseTimerParams(duration) : null;

  if (!timerParams) {
    return <Navigate to="/countdown" replace />;
  }

  const { value, unit } = timerParams;
  const milliseconds = unit === 'second' ? value * 1000
    : unit === 'minute' ? value * 60 * 1000
    : value * 60 * 60 * 1000;

  return (
    <PresetTimer
      milliseconds={milliseconds}
      title={formatTimerTitle(value, unit)}
      path={formatTimerPath(value, unit)}
      unit={unit}
      value={value}
    />
  );
};

export default DynamicTimer;