import React, { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTimer } from '../hooks/useTimer';
import TimerDisplay from '../components/TimerDisplay';
import TimerControls from '../components/TimerControls';
import { Brain, Coffee, Forward } from 'lucide-react';

type PomodoroPhase = 'work' | 'shortBreak' | 'longBreak';

const PHASE_TIMES = {
  work: 25 * 60 * 1000, // 25 minutes
  shortBreak: 5 * 60 * 1000, // 5 minutes
  longBreak: 15 * 60 * 1000, // 15 minutes
};

const PHASE_CONFIGS = {
  work: {
    icon: Brain,
    title: 'Focus Time',
    color: 'bg-red-500',
  },
  shortBreak: {
    icon: Coffee,
    title: 'Short Break',
    color: 'bg-green-500',
  },
  longBreak: {
    icon: Coffee,
    title: 'Long Break',
    color: 'bg-blue-500',
  },
};

const Pomodoro = () => {
  const [phase, setPhase] = useState<PomodoroPhase>('work');
  const [pomodoroCount, setPomodoroCount] = useState(0);

  const onComplete = useCallback(() => {
    if (phase === 'work') {
      const newCount = pomodoroCount + 1;
      setPomodoroCount(newCount);
      setPhase(newCount % 4 === 0 ? 'longBreak' : 'shortBreak');
    } else {
      setPhase('work');
    }
  }, [phase, pomodoroCount]);

  const { time, isRunning, start, pause, reset } = useTimer({
    countDown: true,
    initialTime: PHASE_TIMES[phase],
    onComplete,
  });

  const formatTime = (ms: number) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return { hours: 0, minutes, seconds };
  };

  const formattedTime = formatTime(time);
  const PhaseIcon = PHASE_CONFIGS[phase].icon;

  const handleSkipPhase = () => {
    reset();
    onComplete();
  };

  return (
    <>
      <Helmet>
        <title>Pomodoro Timer - Focus & Productivity | TimeMaster</title>
        <meta name="description" content="Boost your productivity with our Pomodoro Timer. Alternate between focused work sessions and refreshing breaks for maximum efficiency." />
        <meta name="keywords" content="pomodoro timer, productivity timer, focus timer, work breaks" />
        <link rel="canonical" href="/pomodoro" />
        <meta property="og:title" content="Pomodoro Timer for Enhanced Productivity" />
        <meta property="og:description" content="Use the Pomodoro Technique to improve focus and productivity with timed work sessions and breaks." />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Pomodoro Timer
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Enhance your productivity with focused work sessions
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8">
          <div className="flex justify-center items-center mb-8">
            <div className={`${PHASE_CONFIGS[phase].color} p-4 rounded-full`}>
              <PhaseIcon className="w-8 h-8 text-white" />
            </div>
            <h2 className="text-2xl font-semibold ml-4 text-gray-900 dark:text-white">
              {PHASE_CONFIGS[phase].title}
            </h2>
          </div>

          <TimerDisplay
            hours={formattedTime.hours}
            minutes={formattedTime.minutes}
            seconds={formattedTime.seconds}
          />

          <div className="flex flex-col items-center">
            <TimerControls
              isRunning={isRunning}
              onStart={start}
              onPause={pause}
              onReset={reset}
            />

            <button
              onClick={handleSkipPhase}
              className="mt-4 flex items-center gap-2 px-4 py-2 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white transition-colors"
            >
              <Forward className="w-4 h-4" />
              Skip to Next Phase
            </button>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
            Progress
          </h3>
          <div className="flex justify-between items-center">
            <div className="text-gray-600 dark:text-gray-300">
              Completed Pomodoros: {pomodoroCount}
            </div>
            <div className="text-gray-600 dark:text-gray-300">
              Current Phase: {PHASE_CONFIGS[phase].title}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pomodoro;