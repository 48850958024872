import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTimer } from '../hooks/useTimer';
import TimerDisplay from '../components/TimerDisplay';
import TimerControls from '../components/TimerControls';

const Stopwatch = () => {
  const { time, isRunning, start, pause, reset, lap, laps } = useTimer({
    interval: 10,
  });

  const formatTime = (ms: number) => {
    const hours = Math.floor(ms / 3600000);
    const minutes = Math.floor((ms % 3600000) / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    const milliseconds = ms % 1000;
    return { hours, minutes, seconds, milliseconds };
  };

  const formattedTime = formatTime(time);

  return (
    <>
      <Helmet>
        <title>Stopwatch with Lap Timer | TimeMaster</title>
        <meta name="description" content="Professional stopwatch with lap timing functionality. Perfect for sports, training, and precise time measurements." />
        <meta name="keywords" content="stopwatch, lap timer, sports timer, precision timing" />
        <link rel="canonical" href="/stopwatch" />
        <meta property="og:title" content="Professional Stopwatch with Lap Timer" />
        <meta property="og:description" content="High-precision stopwatch with lap timing functionality for sports and training." />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Professional Stopwatch
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            High-precision timing with lap functionality
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8">
          <TimerDisplay
            hours={formattedTime.hours}
            minutes={formattedTime.minutes}
            seconds={formattedTime.seconds}
            milliseconds={formattedTime.milliseconds}
            showMilliseconds={true}
          />

          <TimerControls
            isRunning={isRunning}
            onStart={start}
            onPause={pause}
            onReset={reset}
            onLap={lap}
            showLap={true}
          />
        </div>

        {laps.length > 0 && (
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
              Lap Times
            </h2>
            <div className="divide-y divide-gray-200 dark:divide-gray-700">
              {laps.map((lapTime, index) => {
                const lapFormatted = formatTime(lapTime);
                return (
                  <div
                    key={index}
                    className="py-3 flex justify-between items-center text-lg"
                  >
                    <span className="font-medium text-gray-700 dark:text-gray-300">
                      Lap {laps.length - index}
                    </span>
                    <span className="font-mono text-gray-900 dark:text-white">
                      {lapFormatted.hours > 0 && `${lapFormatted.hours}:`}
                      {lapFormatted.minutes.toString().padStart(2, '0')}:
                      {lapFormatted.seconds.toString().padStart(2, '0')}.
                      {Math.floor(lapFormatted.milliseconds / 10)
                        .toString()
                        .padStart(2, '0')}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Stopwatch;