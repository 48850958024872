import { useState, useEffect, useCallback, useRef } from 'react';
import useSound from 'use-sound';

interface TimerConfig {
  countDown?: boolean;
  initialTime?: number;
  interval?: number;
  onComplete?: () => void;
}

export const useTimer = ({
  countDown = false,
  initialTime = 0,
  interval = 10,
  onComplete,
}: TimerConfig = {}) => {
  const [time, setTime] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  const [laps, setLaps] = useState<number[]>([]);
  const intervalRef = useRef<number>();
  const [playStart] = useSound('/sounds/start.mp3');
  const [playStop] = useSound('/sounds/stop.mp3');
  const [playComplete] = useSound('/sounds/complete.mp3');

  const start = useCallback(() => {
    if (!isRunning) {
      setIsRunning(true);
      playStart();
      const startTime = Date.now() - (countDown ? initialTime - time : time);
      
      intervalRef.current = window.setInterval(() => {
        const currentTime = Date.now();
        const deltaTime = currentTime - startTime;
        const newTime = countDown ? initialTime - deltaTime : deltaTime;

        if (countDown && newTime <= 0) {
          clearInterval(intervalRef.current);
          setTime(0);
          setIsRunning(false);
          playComplete();
          onComplete?.();
        } else {
          setTime(newTime);
        }
      }, interval);
    }
  }, [isRunning, time, countDown, initialTime, interval, onComplete, playStart, playComplete]);

  const pause = useCallback(() => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
    playStop();
  }, [playStop]);

  const reset = useCallback(() => {
    clearInterval(intervalRef.current);
    setTime(initialTime);
    setIsRunning(false);
    setLaps([]);
    playStop();
  }, [initialTime, playStop]);

  const lap = useCallback(() => {
    setLaps(prev => [...prev, time]);
  }, [time]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    setTime(initialTime);
  }, [initialTime]);

  return {
    time,
    isRunning,
    start,
    pause,
    reset,
    lap,
    laps,
  };
};