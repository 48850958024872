import React from 'react';

interface TimerDisplayProps {
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds?: number;
  showMilliseconds?: boolean;
}

const TimerDisplay: React.FC<TimerDisplayProps> = ({
  hours,
  minutes,
  seconds,
  milliseconds = 0,
  showMilliseconds = false,
}) => {
  const formatNumber = (num: number, digits: number = 2) => {
    return num.toString().padStart(digits, '0');
  };

  return (
    <div className="font-mono text-6xl md:text-8xl text-center text-gray-900 dark:text-white">
      {hours > 0 && `${formatNumber(hours)}:`}
      {formatNumber(minutes)}:{formatNumber(seconds)}
      {showMilliseconds && (
        <span className="text-4xl md:text-6xl text-gray-700 dark:text-gray-300">
          .{formatNumber(Math.floor(milliseconds / 10), 2)}
        </span>
      )}
    </div>
  );
};

export default TimerDisplay;