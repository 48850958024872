import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useTimer } from '../hooks/useTimer';
import TimerDisplay from '../components/TimerDisplay';
import TimerControls from '../components/TimerControls';
import { Timer, Clock } from 'lucide-react';
import { 
  SECOND_DURATIONS, 
  MINUTE_DURATIONS, 
  HOUR_DURATIONS,
  formatTimerPath,
  formatDuration
} from '../utils/timerRoutes';

// Common presets in seconds and minutes
const COMMON_SECOND_PRESETS = [15, 30, 45, 60];
const COMMON_MINUTE_PRESETS = [1, 2, 3, 5, 10, 15, 20, 25, 30, 45, 60];
const COMMON_HOUR_PRESETS = [1, 2, 3, 4, 8, 12, 24];

const Countdown = () => {
  const [selectedTime, setSelectedTime] = useState(300000); // 5 minutes default

  const { time, isRunning, start, pause, reset } = useTimer({
    countDown: true,
    initialTime: selectedTime,
  });

  const formatTime = (ms: number) => {
    const hours = Math.floor(ms / 3600000);
    const minutes = Math.floor((ms % 3600000) / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return { hours, minutes, seconds };
  };

  const formattedTime = formatTime(time);

  return (
    <>
      <Helmet>
        <title>Countdown Timer | TimeMaster</title>
        <meta name="description" content="Professional countdown timer with customizable presets. Perfect for presentations, cooking, and time management." />
        <meta name="keywords" content="countdown timer, timer with presets, presentation timer" />
        <link rel="canonical" href="/countdown" />
        <meta property="og:title" content="Professional Countdown Timer" />
        <meta property="og:description" content="Customizable countdown timer with presets for various timing needs." />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Countdown Timer
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Set custom countdowns or choose from presets
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8">
          <div className="flex justify-center items-center mb-8">
            <Timer className="w-8 h-8 text-blue-500 dark:text-blue-400" />
          </div>

          <TimerDisplay
            hours={formattedTime.hours}
            minutes={formattedTime.minutes}
            seconds={formattedTime.seconds}
          />

          <TimerControls
            isRunning={isRunning}
            onStart={start}
            onPause={pause}
            onReset={reset}
          />
        </div>

        <div className="space-y-8">
          {/* Seconds Presets */}
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
              Second Timers
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
              {SECOND_DURATIONS.map((seconds) => (
                <Link
                  key={`${seconds}s`}
                  to={formatTimerPath(seconds, 'second')}
                  className="p-2 text-center rounded transition-colors hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                >
                  {formatDuration(seconds, 'second')}
                </Link>
              ))}
            </div>
          </div>

          {/* Minutes Presets */}
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
              Minute Timers
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
              {MINUTE_DURATIONS.map((minutes) => (
                <Link
                  key={`${minutes}m`}
                  to={formatTimerPath(minutes, 'minute')}
                  className="p-2 text-center rounded transition-colors hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                >
                  {formatDuration(minutes, 'minute')}
                </Link>
              ))}
            </div>
          </div>

          {/* Hours Presets */}
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
              Hour Timers
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
              {HOUR_DURATIONS.map((hours) => (
                <Link
                  key={`${hours}h`}
                  to={formatTimerPath(hours, 'hour')}
                  className="p-2 text-center rounded transition-colors hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                >
                  {formatDuration(hours, 'hour')}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Countdown;