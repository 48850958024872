import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Timer, GraduationCap, Users, Clock } from 'lucide-react';

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | ClassroomTimer.org</title>
        <meta name="description" content="Learn about ClassroomTimer.org - Your professional classroom timing solution" />
        <meta name="keywords" content="classroom timer, education timer, about us, school timer" />
        <link rel="canonical" href="/about" />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            About ClassroomTimer.org
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Professional timing solutions for modern education
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8">
          <div className="flex justify-center items-center mb-8">
            <div className="bg-indigo-500 p-4 rounded-full">
              <GraduationCap className="w-8 h-8 text-white" />
            </div>
          </div>

          <div className="prose dark:prose-invert max-w-none">
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              ClassroomTimer.org is dedicated to providing educators, students, and educational institutions with professional-grade timing tools. Our platform is designed to enhance classroom management and improve learning efficiency through precise time management.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">Our Mission</h2>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              We strive to create the most user-friendly and reliable timing tools for educational environments. Our goal is to help teachers manage classroom activities effectively and students develop better time management skills.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">Key Features</h2>
            <ul className="list-disc pl-6 text-gray-700 dark:text-gray-300 mb-6">
              <li>Multiple timer types for different educational needs</li>
              <li>Clean, distraction-free interface</li>
              <li>Accessible on any device</li>
              <li>No registration required</li>
              <li>Completely free to use</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">Why Choose Us?</h2>
            <p className="text-gray-700 dark:text-gray-300">
              ClassroomTimer.org is built with modern education in mind. We understand the unique timing needs of classrooms and have crafted our tools to meet these specific requirements while maintaining simplicity and reliability.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;