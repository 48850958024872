import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTimer } from '../hooks/useTimer';
import TimerDisplay from '../components/TimerDisplay';
import TimerControls from '../components/TimerControls';
import { Dumbbell, Timer } from 'lucide-react';

const DEFAULT_WORK = 30000; // 30 seconds
const DEFAULT_REST = 10000; // 10 seconds
const DEFAULT_ROUNDS = 8;

const Interval = () => {
  const [workTime] = useState(DEFAULT_WORK);
  const [restTime] = useState(DEFAULT_REST);
  const [currentRound, setCurrentRound] = useState(1);
  const [isWorkPhase, setIsWorkPhase] = useState(true);

  const onComplete = () => {
    if (isWorkPhase) {
      if (currentRound < DEFAULT_ROUNDS) {
        setIsWorkPhase(false);
      }
    } else {
      setCurrentRound(prev => prev + 1);
      setIsWorkPhase(true);
    }
  };

  const { time, isRunning, start, pause, reset } = useTimer({
    countDown: true,
    initialTime: isWorkPhase ? workTime : restTime,
    onComplete,
  });

  const formatTime = (ms: number) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return { hours: 0, minutes, seconds };
  };

  const formattedTime = formatTime(time);

  return (
    <>
      <Helmet>
        <title>HIIT Interval Timer | TimeMaster</title>
        <meta name="description" content="Professional interval timer for HIIT workouts and circuit training. Customize work/rest periods for effective training." />
        <meta name="keywords" content="HIIT timer, interval timer, workout timer, circuit training" />
        <link rel="canonical" href="/interval" />
        <meta property="og:title" content="Professional HIIT Interval Timer" />
        <meta property="og:description" content="Interval timer for high-intensity interval training and circuit workouts." />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            HIIT Interval Timer
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            High-intensity interval training timer
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8">
          <div className="flex justify-center items-center mb-8">
            {isWorkPhase ? (
              <div className="bg-red-500 p-4 rounded-full">
                <Dumbbell className="w-8 h-8 text-white" />
              </div>
            ) : (
              <div className="bg-green-500 p-4 rounded-full">
                <Timer className="w-8 h-8 text-white" />
              </div>
            )}
          </div>

          <div className="text-center mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
              {isWorkPhase ? 'Work Phase' : 'Rest Phase'}
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Round {currentRound} of {DEFAULT_ROUNDS}
            </p>
          </div>

          <TimerDisplay
            hours={formattedTime.hours}
            minutes={formattedTime.minutes}
            seconds={formattedTime.seconds}
          />

          <TimerControls
            isRunning={isRunning}
            onStart={start}
            onPause={pause}
            onReset={reset}
          />
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                Work Time
              </h3>
              <p className="text-gray-600 dark:text-gray-300">30 seconds</p>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                Rest Time
              </h3>
              <p className="text-gray-600 dark:text-gray-300">10 seconds</p>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                Rounds
              </h3>
              <p className="text-gray-600 dark:text-gray-300">{DEFAULT_ROUNDS}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Interval;