import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Mail, ExternalLink } from 'lucide-react';

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | ClassroomTimer.org</title>
        <meta name="description" content="Get in touch with ClassroomTimer.org team for support or feedback" />
        <meta name="keywords" content="contact, support, feedback, classroom timer" />
        <link rel="canonical" href="/contact" />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Contact Us
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            We'd love to hear from you
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8">
          <div className="flex justify-center items-center mb-8">
            <div className="bg-indigo-500 p-4 rounded-full">
              <Mail className="w-8 h-8 text-white" />
            </div>
          </div>

          <div className="text-center max-w-2xl mx-auto">
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Have questions, suggestions, or feedback? We're here to help! Reach out to us at:
            </p>
            
            <a 
              href="mailto:chat@ikshitij.com"
              className="inline-flex items-center text-lg text-indigo-600 dark:text-indigo-400 hover:text-indigo-700 dark:hover:text-indigo-300"
            >
              chat@ikshitij.com
              <ExternalLink className="w-4 h-4 ml-2" />
            </a>

            <p className="mt-8 text-gray-700 dark:text-gray-300">
              We typically respond within 24 hours during business days. Your feedback helps us improve ClassroomTimer.org for everyone.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;