// Timer durations in seconds
export const SECOND_DURATIONS = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 15, 20, 25, 30, 32, 35, 40, 45, 50,
  55, 59, 60, 65, 70, 75, 80, 90, 100, 120, 150, 180, 200, 300, 600, 1000
];

// Timer durations in minutes
export const MINUTE_DURATIONS = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
  59, 60, 62, 65, 70, 75, 80, 85, 90, 95, 99, 100, 105, 110, 115, 120, 150,
  180, 200, 1000, 1111, 3730
];

// Timer durations in hours
export const HOUR_DURATIONS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 20, 23, 24, 30,
  48, 72, 100, 1000
];

export const formatDuration = (value: number, unit: 'second' | 'minute' | 'hour'): string => {
  if (unit === 'hour') {
    return value === 1 ? '1 hour' : `${value} hours`;
  } else if (unit === 'minute') {
    return value === 1 ? '1 minute' : `${value} minutes`;
  }
  return value === 1 ? '1 second' : `${value} seconds`;
};

export const formatTimerPath = (value: number, unit: 'second' | 'minute' | 'hour'): string => {
  return `/timer/${value}-${unit}s`;
};

export const formatTimerTitle = (value: number, unit: 'second' | 'minute' | 'hour'): string => {
  return `${formatDuration(value, unit)} Timer`;
};

export const parseTimerParams = (params: string): { value: number; unit: 'second' | 'minute' | 'hour' } | null => {
  const match = params.match(/^(\d+)-(seconds|minutes|hours)$/);
  if (!match) return null;

  const value = parseInt(match[1], 10);
  const unit = match[2].slice(0, -1) as 'second' | 'minute' | 'hour';

  // Validate the duration exists in our lists
  const isValid = unit === 'second' ? SECOND_DURATIONS.includes(value)
    : unit === 'minute' ? MINUTE_DURATIONS.includes(value)
    : HOUR_DURATIONS.includes(value);

  return isValid ? { value, unit } : null;
};