import React from 'react';
import { Play, Pause, RotateCcw, Flag } from 'lucide-react';

interface TimerControlsProps {
  isRunning: boolean;
  onStart: () => void;
  onPause: () => void;
  onReset: () => void;
  onLap?: () => void;
  showLap?: boolean;
}

const TimerControls: React.FC<TimerControlsProps> = ({
  isRunning,
  onStart,
  onPause,
  onReset,
  onLap,
  showLap = false,
}) => {
  return (
    <div className="flex justify-center gap-4 mt-8">
      <button
        onClick={isRunning ? onPause : onStart}
        className={`rounded-full p-4 text-white transition-colors ${
          isRunning
            ? 'bg-red-500 hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700'
            : 'bg-green-500 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700'
        }`}
      >
        {isRunning ? <Pause className="w-8 h-8" /> : <Play className="w-8 h-8" />}
      </button>
      
      <button
        onClick={onReset}
        className="rounded-full p-4 bg-gray-500 hover:bg-gray-600 dark:bg-gray-600 dark:hover:bg-gray-700 text-white transition-colors"
      >
        <RotateCcw className="w-8 h-8" />
      </button>

      {showLap && onLap && (
        <button
          onClick={onLap}
          className="rounded-full p-4 bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 text-white transition-colors"
        >
          <Flag className="w-8 h-8" />
        </button>
      )}
    </div>
  );
};

export default TimerControls;