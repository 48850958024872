interface TimerContent {
  description: string;
  about: string;
  uses: string[];
}

export const getTimerContent = (minutes: number): TimerContent => {
  switch (minutes) {
    case 1:
      return {
        description: 'Quick and efficient one-minute countdown for rapid tasks',
        about: 'The one-minute timer is perfect for quick tasks that need precise timing. It\'s ideal for short exercises, quick meditation sessions, or timing brief activities with accuracy.',
        uses: [
          'Brushing teeth properly',
          'Quick meditation or breathing exercises',
          'Timing short planks or exercises',
          'Perfect soft-boiled eggs',
          'Social media breaks'
        ]
      };
    case 3:
      return {
        description: 'Three-minute countdown for brief but focused activities',
        about: 'A three-minute timer provides the perfect duration for short but meaningful tasks. It\'s long enough to accomplish quick goals while maintaining focus and efficiency.',
        uses: [
          'Brewing the perfect cup of tea',
          'Quick stretching routines',
          'Mindfulness exercises',
          'Power cleaning sprints',
          'Quick brainstorming sessions'
        ]
      };
    case 5:
      return {
        description: 'Five-minute timer for focused mini-sessions',
        about: 'Five minutes is the sweet spot for many quick but productive activities. This timer helps you maintain focus while completing short tasks effectively.',
        uses: [
          'Quick morning exercises',
          'Power nap preparation',
          'Focused brainstorming',
          'Speed cleaning sessions',
          'Brief meditation practices'
        ]
      };
    case 10:
      return {
        description: 'Ten-minute countdown for productive short sessions',
        about: 'The ten-minute timer is perfect for tasks that need a bit more time but still maintain urgency. It\'s a great duration for meaningful progress without overwhelming time commitment.',
        uses: [
          'Quick workout routines',
          'Efficient meal prep steps',
          'Focused study sprints',
          'Room organization',
          'Morning yoga sessions'
        ]
      };
    case 15:
      return {
        description: 'Quarter-hour timer for substantial tasks',
        about: 'Fifteen minutes provides enough time to accomplish significant tasks while maintaining focus. It\'s the perfect duration for meaningful progress in various activities.',
        uses: [
          'Productive work sprints',
          'Extended exercise routines',
          'Thorough room cleaning',
          'Reading sessions',
          'Creative writing blocks'
        ]
      };
    case 30:
      return {
        description: 'Half-hour countdown for focused achievement',
        about: 'Thirty minutes is ideal for substantial tasks that require dedicated focus. This timer helps you maintain productivity during longer work or activity sessions.',
        uses: [
          'Focused work blocks',
          'Complete workout sessions',
          'Meal preparation',
          'Study sessions',
          'Project planning'
        ]
      };
    case 60:
      return {
        description: 'One-hour timer for maximum productivity',
        about: 'A full hour provides the perfect duration for deep work and significant accomplishments. This timer helps you maintain focus during extended sessions while tracking your progress.',
        uses: [
          'Deep work sessions',
          'Extended workouts',
          'Comprehensive study blocks',
          'Major cleaning tasks',
          'Long-form content creation'
        ]
      };
    default:
      return {
        description: 'Professional timer for precise timing',
        about: 'This timer is designed for accurate timing of various activities.',
        uses: [
          'Time management',
          'Task timing',
          'Activity tracking',
          'Productivity sessions',
          'General timing needs'
        ]
      };
  }
};