import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/Layout';
import Home from './pages/Home';
import Stopwatch from './pages/Stopwatch';
import Countdown from './pages/Countdown';
import Interval from './pages/Interval';
import Pomodoro from './pages/Pomodoro';
import Egg from './pages/Egg';
import Meeting from './pages/Meeting';
import About from './pages/About';
import Contact from './pages/Contact';
import DynamicTimer from './pages/DynamicTimer';
import { ThemeProvider } from './contexts/ThemeContext';
import LoadingSpinner from './components/LoadingSpinner';

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <BrowserRouter>
          <Layout>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/stopwatch" element={<Stopwatch />} />
                <Route path="/countdown" element={<Countdown />} />
                <Route path="/interval" element={<Interval />} />
                <Route path="/pomodoro" element={<Pomodoro />} />
                <Route path="/egg" element={<Egg />} />
                <Route path="/meeting" element={<Meeting />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/timer/:duration" element={<DynamicTimer />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Suspense>
          </Layout>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;